.video-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    width: 100%;
    border-radius: 5px;

    .video-js {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    
        .vjs-big-play-button {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border: 0;
            background-color: #fff;
            width: 60px;
            height: 60px;
            border-radius: 50%;

            .vjs-icon-placeholder {
                &::before {
                    color: red!important;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
    .vjs-poster {
        background-size: cover;
    }
}
 