//---------------------------------------------
//
//  Variables
//
//    1. Bootstrap Fonts
//    2. Bootstrap Globals
//    3. Bootstrap Colors
//    4. Bootstrap Container Width
//
//---------------------------------------------

//---------------------------------------------
// 1. Bootstrap Fonts
//---------------------------------------------

$font-family-base: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

//---------------------------------------------
// 2. Bootstrap Globals
//---------------------------------------------

$enable-rounded: true;
$enable-shadows: false;
$enable-gradients: false;

//---------------------------------------------
// 3. Bootstrap Colors
//---------------------------------------------

// $body-color: #fff; // The default text color

$primary:    #024cae;
$secondary:  #1B98F3;
$light: #dfdfdf;
$success:    #198754;
$info:       #0dcaf0;
$warning:    #ffc107;
$danger:     #dc3545;
// $light:      #0B0B0B;
$dark:       #000000;
$border-color: #d6d6d6;

// breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1025px,
  xl: 1200px,
  xxl: 1400px,
);

//---------------------------------------------
// 4. Bootstrap Container Width
//---------------------------------------------

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px
);

// My Custom Variables
$para-color:  #878787;
$base-font: 'Poppins', 'Helvetica Neue', 'Arial', sans-serif;

// Bootstrap Components Overriding
// buttons
$btn-padding-y: 24px;
$btn-padding-x: 64px;
$btn-font-family: "Poppins", "Helvetica", "Arial", sans-serif;
$btn-font-size: 18px;
$btn-white-space: nowrap;
$btn-link-color: #fff;
$btn-link-hover-color: #fff;
$btn-border-width: 2px;
$btn-line-height: 1;
$btn-border-radius: 12px;
$btn-font-weight: 600;
$btn-focus-box-shadow: none;
$btn-active-box-shadow: none;

$box-shadow: 0 0 10px rgba(0,0,0,0.25);

