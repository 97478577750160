.hero-section {
    position: relative;
    background-image: url(../images//hero-bg.webp);
    @include backgroundImageSetting(center center, cover, no-repeat);
    height: 700px;
    overflow: hidden;

    .container,
    .row {
        height: 100%; 
    }
    .logo-col {
        position: absolute;
        top: 32px;
        width: 100%;
        display: block;
        text-align: center;
        left: 0;

        img {
            max-width: 180px;
        }
    }
    .hero-content-col {
        margin: 0 0 2.5rem 0;
        transition: all 0.6s ease-in-out;
        transition-delay: 0.3s;
        z-index: 10;
        text-align: center;
        height: 50%;
        display: flex;
        align-items: flex-end;
        justify-content: center;

        .hero__content-block {
            .cta-container {
                transform: translateY(0px);
            }
        }
    }

    .hero-img-col {
        z-index: 9;
        height: 50%;

        .hero__img-container {
            .main-frame-img {
                opacity: 0.75;
                transition: opacity 0.6s ease-in-out;
                transition-delay: 1.5s;
            }
            .grid-items {
                position: absolute;
                bottom: 40px;
                left: 8px;
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                width: calc(100% - 16px);
                gap: 4px;

                .grid-item {
                    transition: transform 0.6s ease-in-out;
                    transition-delay: 0.9s;
                    img {
                        border: 1px solid rgba(255,255,255,0.15);
                        border-radius: 5px;
                    }
                }
            }
        }
    }

    .ext-logo-col {
        position: absolute;
        top: 150px;
        max-width: 1320px;
        display: none;
        justify-content: center;
        transition: all 0.3s ease-in-out;

        .ext-logo {
            max-width: 110px;
            z-index: 100;
        }
    }

    .ext-logo-col--v2 {
        position: absolute;
        bottom: 20px;
        left: 0;
        display: flex;
        justify-content: center;
        opacity: 1;
        z-index: 20;

        img {
            margin-right: 0.625rem;
            max-width: 60px;
        }
    }
}
@include media-breakpoint-up(sm) {
    .hero-section {
        height: 800px;
    }
}
@include media-breakpoint-up(md) {
    .hero-section {
        height: 750px;

        .hero-img-col {
            .hero__img-container {
                margin: 0 8rem;
            }
        }
    }
}
@include media-breakpoint-up(lg) {
    .hero-section {
        height: 700px;

        .hero-content-col {
            padding-top: 0;
            transform: translateX(50%);
            display: block;
            height: auto;
    
            .hero__content-block {
                .cta-container {
                    transform: translateY(180px);
                }
            }
        }

        .hero-img-col {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            transition: all 0.6s ease-in-out;
            transition-delay: 1.5s;
            z-index: 9;
            height: auto;
    
            .hero__img-container {
                margin: 0;

                .main-frame-img {
                    opacity: 0.75;
                    transition: opacity 0.6s ease-in-out;
                    transition-delay: 1.5s;
                }
                .grid-items {
                    position: absolute;
                    bottom: 40px;
                    left: 8px;
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                    width: calc(100% - 16px);
                    gap: 4px;
    
                    .grid-item {
                        transition: transform 0.6s ease-in-out;
                        transition-delay: 0.9s;
                        img {
                            border: 1px solid rgba(255,255,255,0.15);
                            border-radius: 5px;
                        }
    
                        &.grid-item--1 {
                            transform: translate(-200px, -120px) scale(0.75);
                        }
                        &.grid-item--2 {
                            transform: translate(-250px, -200px) scale(0.75);
                            
                        }
                        &.grid-item--3 {
                            transform: translate(150px, -200px) scale(0.65);
                            
                        }
                    }
                }
            }
        }
    
        .ext-logo-col {
            display: flex;
        }

        .ext-logo-col {
            display: flex;
            .ext-logo {
                &.ext-logo--1 {
                    transform: translate(-50px, -20px); 
                 }
                 &.ext-logo--2 {
                     transform: translate(0, -50px) scale(0.75);
                 }
                 &.ext-logo--3 {
                     transform: translate(80px, -30px) scale(1.1);
                 }
                 &.ext-logo--4 {
                     transform: translate(320px, 270px) scale(1.1);
                 }
                &.ext-logo--4 {
                    transform: translate(120px, 200px) scale(1.1);
                }
            }
        }

        .ext-logo-col--v2 {
            bottom: 80px;
            opacity: 0;
            transition: opacity 0.9s ease-in-out;
            transition-delay: 1.5s;
            z-index: 20;
    
            img {
                margin-right: 0.625rem;
                max-width: 120px;
            }
        }

        &.hero-section--shrinked {
            .hero-content-col {
                text-align: left;
                transform: none;
    
                .hero__content-block {
                    .cta-container {
                        transform: translateY(0px);
                        margin-top: 1.5rem;
                    }
                }
            }
            .hero-img-col{
                position: relative;
                top: auto;
                left: auto;
                transform: none;
    
                .main-frame-img {
                    opacity: 1;
                }
                .hero__img-container {
                    .grid-items {
                        .grid-item {
                            &.grid-item--1,
                            &.grid-item--2,
                            &.grid-item--3 {
                                transform: none;
                            }
                        }
                    }
                }
            }
           .ext-logo-col {
             opacity: 0;
             pointer-events: none;
           } 
    
           .ext-logo-col--v2 {
            opacity: 1;
           }
        }
    }  
}
@include media-breakpoint-up(xl) {
    .hero-section {
        height: 800px;

        .hero-img-col {
            .hero__img-container {
                .grid-items {
                    .grid-item {
                        &.grid-item--1 {
                            transform: translate(-240px, -120px) scale(0.75);
                        }
                        &.grid-item--2 {
                            transform: translate(-300px, -250px) scale(0.75);
                            
                        }
                        &.grid-item--3 {
                            transform: translate(180px, -250px) scale(0.65);
                            
                        }
                    }
                }
            }
        }

        .ext-logo-col {
            .ext-logo {
                &.ext-logo--1 {
                    transform: translate(-50px, -20px); 
                 }
                 &.ext-logo--2 {
                     transform: translate(0, 10px) scale(1.1);
                 }
                 &.ext-logo--3 {
                     transform: translate(80px, -30px) scale(1.1);
                 }
                 &.ext-logo--4 {
                     transform: translate(320px, 270px) scale(1.1);
                 }
                &.ext-logo--4 {
                    transform: translate(170px, 200px) scale(1.1);
                }
            }
        }
    }  
}
@include media-breakpoint-up(xxl) {
   .hero-section {
    height: 1000px;
    .hero-img-col {
        .hero__img-container {
            max-width: 676px;
            margin: 0 auto;

            .grid-items {
                .grid-item {
                    &.grid-item--1 {
                        transform: translate(-200px, -180px) scale(0.75);
                    }
                    &.grid-item--2 {
                        transform: translate(-360px, -340px) scale(0.75);
                        
                    }
                    &.grid-item--3 {
                        transform: translate(160px, -370px) scale(0.65);
                        
                    }
                }
            }
        }
    }

    .ext-logo-col {
        display: flex;
        .ext-logo {
            &.ext-logo--1 {
                transform: translate(-50px, -20px); 
             }
             &.ext-logo--2 {
                 transform: translate(0, 40px) scale(1.2);
             }
             &.ext-logo--3 {
                 transform: translate(80px, -30px) scale(1.1);
             }
             &.ext-logo--4 {
                 transform: translate(320px, 270px) scale(1.1);
             }
            &.ext-logo--4 {
                transform: translate(250px, 200px) scale(1.1);
            }
        }
    }
   } 
}
@media (min-width: 1700px) {

}
// FAQs section
.faqs-section {
	.accordion-container {
        position: relative;
		max-width: 100%;
		height: auto;
		margin: 10px auto;

		>h2 {
			text-align: center;
			color: $dark;
			padding-bottom: 5px;
			margin-bottom: 20px;
			padding-bottom: 15px;
			border-bottom: 1px solid $border-color;
            font-size: 1.15rem;
		}
	}
	.set {
		position: relative;
		width: 100%;
		height: auto;
		background-color: #fff;
        margin: 0 0 1.15rem 0;
		>a {
			display: flex;
            justify-content: space-between;
			padding: 1.25rem 1.5rem;
			text-decoration: none;
			color: $dark;
			font-weight: 600;
			border: 1px solid $border-color;
            border-radius: 60px;
			-webkit-transition: all 0.2s linear;
			-moz-transition: all 0.2s linear;
			transition: all 0.2s linear;
			i {
				float: right;
				margin-top: 2px;
			}
		}
		>a.active {
			background-color: $primary;
			color: #fff;
		}
	}
	.content {
		background-color: #fff;
		display: none;
		p {
			padding: 0.75rem;
			margin: 0;
			color: $para-color;
		}
	}
}

// Stream on diffrent screens section
.stream-diff-screens-section {
    .screen-card-container {
        margin: 0 0 1.5rem 0;

        &:last-child {
            margin: 0;
        }
    }
}
@include media-breakpoint-up(md) {
    .stream-diff-screens-section {
        .screen-card-container {
            margin: 0;

            .screen-card {
                .screen-card__body {
                    .screen-card__title {
                        height: 56px;
                    }
                }
            }
        }
    }   
}
@media (min-width: 992px) {
    .stream-diff-screens-section {
        .screen-card-container {
            .screen-card {
                .screen-card__body {
                    .screen-card__title {
                        height: auto;
                    }
                }
            }
        }
    }
}

// Smart Home Section 
.smart-home-section {
    h2 {
        font-size: 2.125rem;
    }

    .images-list {
        img {
            display: none;

            &.active {
                display: block;
            }
        }
    }

    .smart-home-tab {
        button {
            display: flex;
            align-items: center;
            background-color: transparent;
            width: 100%;
            text-align: left;
            margin-bottom: 1rem;
            border: 0;

            .icon {
                width: 80px;
                height: 80px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 2px solid $primary;
                background-color: #fff;
                border-radius: 50%;

                svg {
                    width: 32px;

                    path {
                        fill: $primary;
                    }
                }
            }
            .text {
                font-weight: 600;
                font-size: 1.15rem;
                color: #fff;
                padding-left: 1rem;
                width: calc(100% - 64px);
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
@include media-breakpoint-up(md) {
    .smart-home-section {
        h2 {
            font-size: 2.75rem; 
        }
    }
}
@include media-breakpoint-up(lg) {
    .smart-home-section {
        background-image: url(../images/right-shape.webp);
        @include backgroundImageSetting(125%, 50%, no-repeat);

        .smart-home-section {
            h2 {
                font-size: 3.375rem; 
            }
        }
    }
}
// Watch with me section 
.watch-with-me-section {
    .swiper {
        .swiper-slide {
            padding-right: 3rem;
            padding-bottom: 3.5rem;

            .discover-block {
                .discover-block__header {
                    position: relative;
                    margin: 0 0 1.25rem 0;

                    .profile-title {
                        font-size: 2rem;
                        position: absolute;
                        bottom: 20px;
                        right: 20px;
                    }
                }
                .discover-block__body {
                    a {
                        color: #fff;
                        font-size: 1.25rem;
                        font-weight: 600;

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }
}

// Why choose section
.why-choose-section {
    .why-choose-block-container {
        .why-choose-block {
            padding: 1.875rem;
            border: 1px solid transparent;
            transition: border 0.3s ease-in-out;
            border-radius: 5px;

            .why-choose-block__header {
                .why-choose-block__icon {
                    width: 48px;
                    display: inline-block;
                }
            }

            .why-choose-block__body {
                .why-choose-block__title {
                    height: 56px;
                }
            }

            &:hover {
                border: 1px solid $border-color;
            }
        }
    }
}

// Watch everything section
@include media-breakpoint-up(lg) {
    .watch-everything-section {
        background-image: url(../images/watch-shape.webp);
        @include backgroundImageSetting(left center, 50%, no-repeat);
    }
}


// Apps section 
.apps-section {
    .nav-tab {
        overflow-x: scroll;
        .nav-tab__btn--tab-control {
            background-color: $dark;
            border: 0;
            text-align: center;
            white-space: nowrap;
            margin-right: 1rem;
            position: relative;

            .icon {
                position: relative;
                width: 80px;
                height: 80px;
                // border: 2px solid #fff;
                background-color: rgba(255,255,255,0.15);
                display: inline-flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;

                .icon__main {
                    width: 24px;

                        path {
                            fill: #fff;
                        }
                }
            }

            // .icon-circle {
            //     width: 77px;
            //     height: 77px;
            //     position: absolute;
            //     top: 40px;
            //     left: 50%;
            //     transform: translate(-50%, -50%);
            //     fill: none;

            //     circle {
            //         width: 78px;
            //         height: 78px;
            //         position: absolute;
            //         top: 40px;
            //         left: 50%;
            //         transform: translate(-50%, -50%);
            //         stroke: $primary;
            //         stroke-width: 2px;
            //         transform: scale(1);
            //         transform-origin: center;
            //         animation:circular-dash 10s linear forwards;
            //     }
            // }
            .tab-icons__timer--circular {
                fill: #fff;
                transform: rotate(270deg) scale(1);
                transform-origin: center;
                transition: transform .3s ease-in-out, fill .3s ease-in-out;   width: 154px;
                /* height: 144px; */
                position: absolute;
                margin: -37px -117px;
            }
                      
                  .tab-icons__timer--circular {
                fill: var(--color-white);
                -webkit-filter: drop-shadow(0 0 4px var(--color-light-black));
                filter: drop-shadow(0 0 4px var(--color-light-black));
                stroke-linecap: round;
                transform: rotate(270deg);
                transform-origin: center;
              }
                      
              .tab-icons__timer__background {
                stroke: #fff;
                transition: stroke .3s ease-in-out;
              }		
              .tab-icons__timer__bar {
                stroke: $primary;
                stroke-width: 2;
                transform-origin: center;
                transition: stroke-width .1s ease-in-out;
                fill:transparent;
                stroke-width:2;
                transform:scale(1);
                transform-origin:center;
                opacity: 0;
              }
            &.active {
                .tab-icons__timer__bar {
                    opacity: 1;
                    -webkit-animation:circular-dash 10s linear forwards;
                    animation:circular-dash 10s linear forwards;
                   }
              }
        }	
                  	
    }

    .tab-content-wrapper {
        .tab-content-block {
            display: none;

            &.active {
                display: block;
            }
        }
    }
}
@keyframes circular-dash {
    0% {
     stroke-dasharray:1, 130
    }
    100% {
     stroke-dasharray:130
    }
}	
@include media-breakpoint-up(md) {
    .apps-section {
        .nav-tab {
            .nav-tab__btn--tab-control {
                margin-right: 0;
            }
        }
    }
}

// show movies section
@include media-breakpoint-up(lg) {
    .update-watchlist-section {
        background-image: url(../images/watch-shape-1.png);
        @include backgroundImageSetting(top right, 47%, no-repeat);
    }
}

// Curated Recommendation Section
.curated-recom-section {
    .bg-images-list {
        img {
            display: none;

            &.active {
                display: block;
            }
        }
    }
    .nav-tab {
        .nav-tab__btn--control {
            .btn-profile {
                width: 80px;
                height: 80px;
                border: 3px solid #7d7878;
                position: relative;

                img {
                    width: 80px;
                }

                .circle-line {
                    stroke-dasharray: 313.6517333984;
                    stroke-dashoffset: 313.6517333984;
                    fill: rgba(255, 255, 255, 0.1);
                    position: absolute;
                    top: -3px;
                    left: -3px;
                    width: 80px;
                    height: 80px;

                    .st0 {
                        stroke: $primary;
                        stroke-width: 3px;
                    }
                  }
            }

            &.active {
                .btn-profile {
                    .circle-line {
                        animation: drawCircle 10s ease-out forwards;
                    }
                }
            }
        }
    }
    .content-images-list {
        position: absolute;
        top: 4px;
        left: 5px;
        right: 5px;
        width: calc(100% - 10px);
        height: calc(100% - 107px);

        img {
            display: none;

            &.active {
                display: block;
            }
        }
    }
}
@keyframes drawCircle {
    to {
      stroke-dashoffset: 0;
    }
}

// Testimonial Section 
.testimonial-section {
    .swiper-slide {
        padding-bottom: 4rem;

        .testimonial-block {
            .testimonial-block__quote {
                font-size: 1.5rem;
                line-height: 1.3;
            }
            .testimonial-block__author {
                position: relative;
    
                &::after {
                    content: '';
                    display: block;
                    position: absolute;
                    top: calc(100% + 20px);
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 80px;
                    height: 3px;
                    background-color: $primary;
                }
            }
        }
    }
}
@include media-breakpoint-up(md) {
    .testimonial-section {
        .swiper-slide {
            .testimonial-block {
                .testimonial-block__quote {
                    font-size: 2rem;
                    padding: 0 10%;
                }
            }
        }
    }
}
@include media-breakpoint-up(lg) {
    .testimonial-section {
        .swiper-slide {
            padding-bottom: 2rem;

            .testimonial-block {
                .testimonial-block__quote {
                    font-size: 2.25rem;
                }
            }
        }

        .swiper-pagination {
            position: absolute;
            top: 50%;
            left: calc(100% - 80px)!important;
            transform: translate(-50%, -50%);
            display: inline-flex;
            flex-direction: column;
            width: auto!important;

            .swiper-pagination-bullet {
                width: 14px;
                height: 14px;
                margin: 0 0 0.625rem 0!important;
                background-color: #c5c5c5!important;
                opacity: 1!important;

                &.swiper-pagination-bullet-active {
                    background-color: $primary!important;
                }
            }
        }
    }
}
@include media-breakpoint-up(xl) {
    .testimonial-section {
        .swiper-slide {
            .testimonial-block {
                .testimonial-block__quote {
                    font-size: 2.5rem;
                    padding: 0 15%;
                }
            }
        }
    }
}
@include media-breakpoint-up(xxl) {
    .testimonial-section {
        .swiper-slide {
            .testimonial-block {
                .testimonial-block__quote {
                    font-size: 3rem;
                    padding: 0 20%;
                }
            }
        }
    }
}



