/* Typography */
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
input,
label,
span {
  font-family: $base-font;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
}

h1 {
  font-size: 2.5rem;
  line-height: 1.025em;
}

h2 {
  font-size: 1.875rem;
  line-height: 1.25em;
}

h3 {
  font-size: 1.375rem;
  line-height: 1.3em;
}

h4 {
  font-size: 1.3rem;
  line-height: 1.3em;
}

h5 {
  font-size: 1.25rem;
  line-height: 1.4em;
}

h6 {
  font-size: 1.15rem;
  line-height: 1.45em;
}

p {
  line-height: 1.5em;
  font-weight: 400;
  font-size: 1rem;
  font-family: $base-font;
}

@include media-breakpoint-up(md) {
  h1 {
    font-size: 3rem;
    line-height: 1.05em;
  }
  h2 {
    font-size: 2rem;
    line-height: 1.2em;
  }
  h4 {
    font-size: 1.35rem;
  }
}
@include media-breakpoint-up(lg) {
  h1 {
    font-size: 3.125rem;
    line-height: 1.05em;
  }
  h2 {
    font-size: 2.5rem;
    line-height: 1.15em;
  }
  h3 {
    font-size: 1.875rem;
    line-height: 1.2em;
  }
}
@include media-breakpoint-up(xl) {
  h1 {
    font-size: 4rem;
    line-height: 1.05em;
  }
  h2 {
    font-size: 3.125rem;
    line-height: 1.15em;
  }
  h3 {
    font-size: 2rem;
    line-height: 1.25em;
  }
  h4 {
    font-size: 1.5rem;
  }
}
@include media-breakpoint-up(xxl) {
  h1 {
    font-size: 4.5rem;
  }
  h2 {
    font-size: 3.75rem;
  }
  h3 {
    font-size: 2.5rem;
  }
}
@media (min-width: 1700px) {
  // h1 {
  //   font-size: 4.5rem;
  // }
  // h2 {
  //   font-size: 3.75rem;
  // }
  // h3 {
  //   font-size: 2.5rem;
  // }
  p {
    font-size: 1.0625rem;
  }
}
