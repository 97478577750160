.btn-primary,
.btn-secondary,
.btn-danger,
.btn-sucess {
    color: #fff;

    &:hover {
        color: #fff;
    }
}

@media (max-width: 1699px) {
    .btn {
        padding: 1rem 2.75rem;
        font-size: 1rem;
    }
}
@include media-breakpoint-down(lg) {
    .btn {
        padding: 0.875rem 2.5rem;
    }
}