.site-footer {
    .footer-menu {
        .menu {
            li {
                margin: 0 0 0.625rem 0;
                a {
                    color: #fff;
                    transition: color 0.3s ease-in-out;

                    &:hover {
                        color: $primary;
                    }
                }
            }
        }
    }
    .social-icons {
        li {
            margin-right: 0.625rem;
            a {
                font-size: 1.0625rem;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                background-color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                color: $dark;
                transition: all 0.3s ease-in-out;
            }
            &:hover {
                a {
                    color: #fff;
                    background-color: $primary;
                }
            }
        }
    }
}