.screen-card {
    overflow: hidden;
    border-radius: 16px;
    .screen-card__header {
        aspect-ratio: 45/35;
    }
    .screen-card__body {
        padding: 1rem;

        .screen-card__title {
            margin: 0 0 0.625rem 0;
            a {
                font-weight: 600;
                font-size: 1.0625rem;
            }
        }
    }
}
@include media-breakpoint-up(md) {
    .screen-card {
        .screen-card__body {
            .screen-card__title {
                a {
                    font-size: 1.15rem;
                }
            }
        }
    }
}
@include media-breakpoint-up(xl) {
    .screen-card {
        .screen-card__body {
            .screen-card__title {
                a {
                    font-size: 1.375rem;
                }
            }
        }
    }
}
@include media-breakpoint-up(xxl) {
    .screen-card {
        .screen-card__body {
            padding: 1.25rem;
            .screen-card__title {
                a {
                    font-size: 1.5rem;
                }
            }
        }
    }
}
